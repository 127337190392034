// eslint-disable-next-line import/prefer-default-export
export const contactData = [
  {
    value: 'living will',
    label: 'livingWill',
  },
  {
    value: 'power of attorney',
    label: 'powerOfAttorney',
  },
  {
    value: 'last will',
    label: 'lastWill',
  },
];
