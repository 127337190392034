import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';

const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  '& .MuiFormControlLabel-root': {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  '& .MuiFormControlLabel-root.Mui-focusVisible': {
    borderColor: theme.palette.primary.main,
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    height: '100%',
  },
  [theme.breakpoints.up('md')]: {
    height: 88,
  },
  margin: 0,
  '& .MuiFormControlLabel-label': {
    width: '100%',
  },
}));

const StyledRadio = styled(Radio)(({ theme }) => ({
  paddingInline: theme.spacing(2),
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export { StyledFormControlLabel, StyledRadio, StyledRadioGroup };
